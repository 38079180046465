import { nrkClose } from '@nrk/core-icons';

export function initializeLoginBanner(window, userName, showCookieBanner) {
  const canReplaceState = window.history && window.history.replaceState
  const loginBanner = document.body.insertAdjacentElement('afterbegin', document.createElement('div'))

  loginBanner.className = 'nrk-masthead__info-banner nrk-masthead__info-banner--login'
  if (showCookieBanner) {
    loginBanner.style = 'display: none;'
  }
  loginBanner.innerHTML = `<span><span class="nrk-masthead__semibold">Hei</span>, du er nå logget på hos nrk.no, fordi du allerede er pålogget et annet sted hos NRK</span><button><span class="nrk-sr">Lukk beskjed om at du er pålogget</span>${nrkClose}</button>`
  loginBanner
    .querySelector('button')
    .addEventListener('click', () => loginBanner.parentElement.removeChild(loginBanner))
  loginBanner.querySelector('.nrk-masthead__semibold').textContent = `Hei ${userName}` // Use textContent for security

  if (canReplaceState) {
    const href = window.location.href.replace(/autoLogin=true&?/, '').replace(/[?|&]$/, '')
    window.history.replaceState(window.history.state, document.title, href)
  }
}



export function initializeCookieBanner(cookieName) {
  const cookieDialog = document.body.insertAdjacentElement('afterbegin', document.createElement('div'))

    cookieDialog.className = 'nrk-masthead__info-banner nrk-masthead__info-banner--cookie'
    cookieDialog.innerHTML = `<span><span class="nrk-masthead__semibold">Vi bruker informasjonskapsler</span> for å forbedre brukeropplevelsen. <a href="https://info.nrk.no/informasjonskapsler">Besøk vår informasjonsside</a> for mer detaljer, eller finn lenken i bunnen av NRK.no.</span><button><span class="nrk-sr">Lukk beskjed om informasjonskapsler</span>${nrkClose}</button>`
    cookieDialog.querySelector('button').addEventListener('click', () => {
      cookieDialog.parentElement.removeChild(cookieDialog)
      const loginBanner = document.querySelector('.nrk-masthead__info-banner--login')
      if (loginBanner) {
        loginBanner.removeAttribute('style')
      }
      const expirationDate = new Date()
      expirationDate.setTime(expirationDate.getTime() + 1000 * 24 * 60 * 60 * 1000)
      document.cookie = `${cookieName}=1; expires=${expirationDate.toUTCString()}; path=/;`
    })
}

window.initializeCookieBanner = initializeCookieBanner;

window.initializeLoginBanner = initializeLoginBanner;